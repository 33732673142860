//overrides
$primary: #343a40;

// Frameworks
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "~admin-lte/dist/css/adminlte.css";

// Croppie
@import "croppie/croppie";

body {
  background-color: #f5f5f5;
}

.content-wrapper {
  padding-top: 20px;
  background-color: transparent;
}

ul.nav ul.nav li {
  padding-left: 10px;
}
.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-primary .nav-sidebar>.nav-item>.nav-link.active {
  background-color: $light;
  color: $dark;
}

.profile-wrapper {
  overflow-wrap: break-word;
}

@media (max-width: 800px) {
  .profile-wrapper {
    padding-left: 6%;
    padding-right: 6%;
  }
}

.bg-blue {
  background-color: #0d6efd!important;
}

.form-signin, .form-register {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within, .form-register .form-floating:focus-within {
  z-index: 2;
}

.form-stack-top {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-stack-middle {
    border-radius: 0;
    margin-bottom: -1px;
}

.form-stack-bottom {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

a {
    text-decoration: none;
}

.tpl-sm-icons a {
  color: inherit;
}

.codify {
  font-size: 12px;
  font-family: menlo, consolas, monospace;
  min-height: 200px !important;
}

.input-disabled, .input-disabled:focus {
  background-color: #f1f1f1;
}

.border-right-flat > input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.tpl-line-wrapper {
  grid-template-columns: 50px minmax(auto, 70%);
  row-gap: 10px;
  margin-left: -50px;
}

.tpl-download img {
  width: 230px;
}

.list-group-socialmedia {
  margin-left: -20px;
  margin-right: -20px;
}

.list-group-socialmedia > .list-group-item {
  border-left: 0;
  border-right: 0;
}

.list-group-socialmedia > .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-socialmedia > .list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion {
  margin-left: -16px;
  margin-right: -16px;
}
.accordion .accordion-item {
  border-left: 0;
  border-right: 0;
}
.accordion .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion .accordion-item:last-of-type {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.accordion-button {
  font-weight: bold;
}
@media (max-width: 800px) {
  .collapsing {
    transition: none;
  }
}



a[data-bs-toggle="collapse"] {
  color: inherit;
  text-decoration: none;

  svg {
    transform: rotate(90deg);
    transition: transform 0.3s;
  }

  &.collapsed svg {
    transform: none;
  }
}


.profile-end-margin {
  padding-bottom: 80px;
}

.page-item.active .page-link {
  background-color: $primary;
  border-color: $primary;
}
